import "../scss/styles.scss"

import {debounce, initScrollListener} from "./common";
initScrollListener();

const images = [];
let prevImageElement = document.getElementById('prev-image');
let currentImageElement = document.getElementById('current-image');
let nextImageElement = document.getElementById('next-image');

let currentPos = 0;
let animating = true;

const cachedImages = new Map();

let mediaSize = 414;

let touchstartX = 0;

function selectMediaSizeBasedOnScreenSize() {

  let screenWidth = screen.width;

  let newMediaSize = 2160;
  if (screenWidth <= 414) {
    newMediaSize = 414;
  } else if (screenWidth <= 1080) {
    newMediaSize = 1080;
  } else if (screenWidth <= 1920) {
    newMediaSize = 1920;
  }

  if (mediaSize !== newMediaSize) {
    mediaSize = newMediaSize;

    cachedImages.clear();

    if(images.length > 0) {
      prefetchImages();
    }
  }
}


function prefetchImages() {
  const start = -2;
  const end = +3;

  for (let pos = start; pos < end; pos++) {
    const id = calcPos(currentPos + pos);
    getOrCreateImage(id);
  }
}


function calcImageSrc(id) {
  id = calcPos(id);
  return 'img/photo/w' + mediaSize + '/' + images[id];
}


function getOrCreateImage(id) {
  id = calcPos(id);
  let image = cachedImages.get(id);
  if (image) {
    return image;
  }

  image = new Image();
  image.src = calcImageSrc(id);
  image.addEventListener("animationend", debounce(animationFinishedEventHandler), {passive: true});
  cachedImages.set(id, image);

  return image;
}


function replaceImage(imageElement, id) {
  const imageElementParent = imageElement.parentElement;
  const newImageElement = getOrCreateImage(id);
  imageElementParent.replaceChild(newImageElement, imageElement);
  return newImageElement;
}


function loadPhotoListAndSetInitialPhotos() {
  fetch('/photolist')
    .then(response => response.json())
    .then(data => {
      images.push(...data);

      prevImageElement = replaceImage(prevImageElement, -1);
      currentImageElement = replaceImage(currentImageElement, 0);
      nextImageElement = replaceImage(nextImageElement, 1);

      animating = false;

      prefetchImages();
    });
}


function calcPos(pos) {
  pos = pos < 0 ? images.length + pos : pos;
  return pos % images.length;
}


function movePrev() {
  let currentlyAnimating = animating;
  if (currentlyAnimating) return;
  animating = true;

  currentPos = calcPos(currentPos - 1);

  currentImageElement.classList.add('moveOutPrev');
  prevImageElement.classList.add('moveInPrev');

  prefetchImages();
}


function moveNext() {
  let currentlyAnimating = animating;
  if (currentlyAnimating) return;
  animating = true;

  currentPos = calcPos(currentPos + 1);

  currentImageElement.classList.add('moveOutNext');
  nextImageElement.classList.add('moveInNext');

  prefetchImages();
}


function movePrevAfterAnimation() {

  const prevParent = prevImageElement.parentElement;
  const currentParent = currentImageElement.parentElement;
  const nextParent = nextImageElement.parentElement;

  currentParent.replaceChild(prevImageElement, currentImageElement);
  nextParent.replaceChild(currentImageElement, nextImageElement);

  prevImageElement.className = '';
  currentImageElement.className = '';
  nextImageElement.className = '';

  nextImageElement = currentImageElement;
  currentImageElement = prevImageElement;

  prevImageElement = getOrCreateImage(calcPos(currentPos - 1));
  prevParent.appendChild(prevImageElement);

  animating = false;
}


function moveNextAfterAnimation() {
  const prevParent = prevImageElement.parentElement;
  const currentParent = currentImageElement.parentElement;
  const nextParent = nextImageElement.parentElement;

  currentParent.replaceChild(nextImageElement, currentImageElement);
  prevParent.replaceChild(currentImageElement, prevImageElement);

  prevImageElement.className = '';
  currentImageElement.className = '';
  nextImageElement.className = '';

  prevImageElement = currentImageElement;
  currentImageElement = nextImageElement;

  nextImageElement = getOrCreateImage(calcPos(currentPos + 1));
  nextParent.appendChild(nextImageElement);

  animating = false;
}


function animationFinishedEventHandler(event) {
  switch (event.animationName) {
    case 'moveInNextFrame':
      moveNextAfterAnimation();
      break;
    case 'moveInPrevFrame':
      movePrevAfterAnimation();
      break;
  }
}


function handleGesture(touchstartX, touchendX) {
  if (Math.abs(touchendX - touchstartX) < 75) {
    return;
  }

  if (touchendX < touchstartX) {
    moveNext();
  }
  if (touchendX > touchstartX) {
    movePrev();
  }
}


function init() {
  if( !currentImageElement ) {
    return;
  }
  selectMediaSizeBasedOnScreenSize();

  loadPhotoListAndSetInitialPhotos();

  window.addEventListener('resize', debounce(selectMediaSizeBasedOnScreenSize));

  document.getElementById(('left')).addEventListener('click', debounce(movePrev), {passive: true});
  document.getElementById(('right')).addEventListener('click', debounce(moveNext), {passive: true});

  currentImageElement.parentElement.addEventListener('touchstart', debounce((event) => {
    touchstartX = event.changedTouches[0].screenX;
  }), {passive: true});

  currentImageElement.parentElement.addEventListener('touchend', debounce((event) => {
    const touchendX = event.changedTouches[0].screenX;
    handleGesture(touchstartX, touchendX);
  }), {passive: true});
}

document.addEventListener('DOMContentLoaded', debounce(init));
