/********** Debounce ***/

export const debounce = (fn) => {
  let frame;

  return (...params) => {
    if (frame) {
      cancelAnimationFrame(frame);
    }

    frame = requestAnimationFrame(() => {
      fn(...params);
    });
  }
};


/********** Top Nav Scrolling annotator ***/

export function initScrollListener() {
  document.addEventListener('scroll', debounce(updateScroll), {passive: true});
  updateScroll();
}

const updateScroll = () => {
  document.documentElement.dataset.scroll = window.scrollY;
}

function setScrollBarWidth() {
  const scrollbarWidth = +(window.innerWidth - document.documentElement.clientWidth);
  document.documentElement.style.setProperty('--scrollbar-width', (scrollbarWidth) + "px");
}

document.addEventListener('DOMContentLoaded',  debounce(setScrollBarWidth));
// window.addEventListener('load', debounce(setScrollBarWidth));
window.addEventListener('resize', debounce(setScrollBarWidth));


/********** Top Nav collapse menu on link click ***/

const navLinks = document.querySelectorAll('.nav-item');
const menuToggle = document.getElementById('navbarLinks');
// const bsCollapse = new Collapse(menuToggle, {toggle: false});
// navLinks.forEach((link) => {
//   link.addEventListener('click', () => {
//     bsCollapse.hide()
//   });
// });

/********** Decode Method ***/

function decode(s) {
  let n = 0;
  let r = "";
  for (let i = 0; i < s.length; i++) {
    n = s.charCodeAt(i);
    if (n >= 8364) {
      n = 128;
    }
    r += String.fromCharCode(n - 1);
  }
  return r;
}


export function replaceDecoded(selector, encodedString) {
  const decodedString = decode(encodedString);
  document.querySelectorAll(selector).forEach( element => {
    element.innerText = decodedString;
  })
}

replaceDecoded('.street', '77!/sutsfhåK');
replaceDecoded('.city', 'sfugmB!85446');
replaceDecoded('.phone', '88662527.2621');
replaceDecoded('.email', 'fe/oftujssfh.obkAnvttfsqnj');

